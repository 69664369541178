.field {
  position: relative;
}

.field__label {
  color: #919293;
  box-sizing: border-box;
  display: block;
  font-size: 0.8125em;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: 0.5em 1em;
  pointer-events: none;
  position: absolute;
  padding-left: 1rem;
  text-overflow: ellipsis;
  top: -0.3em;
  text-align: left;
  transform: translateY(3px);
  transition: all 0.2s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}
.field--not-empty .field__label {
  opacity: 1;
  transform: none;
}

.field__input {
  border: 2px solid #e9e9e9;
  color: #333;
  width: 100%;
  font-size: 1em;
  padding: 0.4761904762em;
  transition: all 0.2s ease-out;
}
.field__input:hover,
.field__input:focus {
  /* inset | offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: inset 0 0 10px 0 rgba(85, 85, 85, 0.1);
  outline: 0;
}
.field--not-empty .field__input {
  padding-bottom: 2px;
  padding-top: 18px;
}
.field__input {
  padding-right: 2rem !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000 !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000 !important;
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000 !important;
  opacity: 1;
}

.ui.dropdown:not(.button) > .default.text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.react-datepicker-popper {
  z-index: 2 !important;
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*!
 * icheck-bootstrap v3.0.1 (https://github.com/bantikyan/icheck-bootstrap)
 * Copyright 2018 Hovhannes Bantikyan.
 * Licensed under MIT (https://github.com/bantikyan/icheck-bootstrap/blob/master/LICENSE)
 */
[class*='icheck-'] {
  min-height: 22px;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  padding-left: 0;
}
.icheck-inline {
  display: inline-block;
}
.icheck-inline + .icheck-inline {
  margin-left: 0.75rem;
  margin-top: 6px;
}
[class*='icheck-'] > label {
  padding-left: 29px !important;
  min-height: 22px;
  line-height: 22px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}
[class*='icheck-'] > input:first-child {
  position: absolute !important;
  opacity: 0;
  margin: 0;
}
[class*='icheck-'] > input:first-child:disabled {
  cursor: default;
}
[class*='icheck-'] > input:first-child + input[type='hidden'] + label::before,
[class*='icheck-'] > input:first-child + label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 22px;
  border: 1px solid #d3cfc8;
  border-radius: 0;
  margin-left: -29px;
}
[class*='icheck-']
  > input:first-child:checked
  + input[type='hidden']
  + label::after,
[class*='icheck-'] > input:first-child:checked + label::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 10px;
  border: 2px solid #fff;
  border-left: none;
  border-top: none;
  transform: translate(7.75px, 4.5px) rotate(45deg);
  -ms-transform: translate(7.75px, 4.5px) rotate(45deg);
}
[class*='icheck-']
  > input[type='radio']:first-child
  + input[type='hidden']
  + label::before,
[class*='icheck-'] > input[type='radio']:first-child + label::before {
  border-radius: 50%;
}
[class*='icheck-']
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
[class*='icheck-']
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-width: 2px;
}
[class*='icheck-'] > input:first-child:disabled + input[type='hidden'] + label,
[class*='icheck-']
  > input:first-child:disabled
  + input[type='hidden']
  + label::before,
[class*='icheck-'] > input:first-child:disabled + label,
[class*='icheck-'] > input:first-child:disabled + label::before {
  pointer-events: none;
  cursor: default;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: 0.65;
}
.icheck-default
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-default
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #adadad;
}
.icheck-default
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-default > input:first-child:checked + label::before {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.icheck-default
  > input:first-child:checked
  + input[type='hidden']
  + label::after,
.icheck-default > input:first-child:checked + label::after {
  border-bottom-color: #333;
  border-right-color: #333;
}
.icheck-primary
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-primary
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #2e6da4;
}
.icheck-primary
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-primary > input:first-child:checked + label::before {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.icheck-success
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-success
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #9fcd3b;
}
.icheck-success
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-success > input:first-child:checked + label::before {
  background-color: #9fcd3b;
  border-color: #9fcd3b;
}
.icheck-info
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-info
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #46b8da;
}
.icheck-info > input:first-child:checked + input[type='hidden'] + label::before,
.icheck-info > input:first-child:checked + label::before {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.icheck-warning
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-warning
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #eea236;
}
.icheck-warning
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-warning > input:first-child:checked + label::before {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.icheck-danger
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-danger
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #d43f3a;
}
.icheck-danger
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-danger > input:first-child:checked + label::before {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.icheck-turquoise
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-turquoise
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #1abc9c;
}
.icheck-turquoise
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-turquoise > input:first-child:checked + label::before {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.icheck-emerland
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-emerland
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #2ecc71;
}
.icheck-emerland
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-emerland > input:first-child:checked + label::before {
  background-color: #2ecc71;
  border-color: #2ecc71;
}
.icheck-peterriver
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-peterriver
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #3498db;
}
.icheck-peterriver
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-peterriver > input:first-child:checked + label::before {
  background-color: #3498db;
  border-color: #3498db;
}
.icheck-amethyst
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-amethyst
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #9b59b6;
}
.icheck-amethyst
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-amethyst > input:first-child:checked + label::before {
  background-color: #9b59b6;
  border-color: #9b59b6;
}
.icheck-wetasphalt
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-wetasphalt
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #34495e;
}
.icheck-wetasphalt
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-wetasphalt > input:first-child:checked + label::before {
  background-color: #34495e;
  border-color: #34495e;
}
.icheck-greensea
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-greensea
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #16a085;
}
.icheck-greensea
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-greensea > input:first-child:checked + label::before {
  background-color: #16a085;
  border-color: #16a085;
}
.icheck-nephritis
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-nephritis
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #27ae60;
}
.icheck-nephritis
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-nephritis > input:first-child:checked + label::before {
  background-color: #27ae60;
  border-color: #27ae60;
}
.icheck-belizehole
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-belizehole
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #2980b9;
}
.icheck-belizehole
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-belizehole > input:first-child:checked + label::before {
  background-color: #2980b9;
  border-color: #2980b9;
}
.icheck-wisteria
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-wisteria
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #8e44ad;
}
.icheck-wisteria
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-wisteria > input:first-child:checked + label::before {
  background-color: #8e44ad;
  border-color: #8e44ad;
}
.icheck-midnightblue
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-midnightblue
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #2c3e50;
}
.icheck-midnightblue
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-midnightblue > input:first-child:checked + label::before {
  background-color: #2c3e50;
  border-color: #2c3e50;
}
.icheck-sunflower
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-sunflower
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #f1c40f;
}
.icheck-sunflower
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-sunflower > input:first-child:checked + label::before {
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.icheck-carrot
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-carrot
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #e67e22;
}
.icheck-carrot
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-carrot > input:first-child:checked + label::before {
  background-color: #e67e22;
  border-color: #e67e22;
}
.icheck-alizarin
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-alizarin
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #e74c3c;
}
.icheck-alizarin
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-alizarin > input:first-child:checked + label::before {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.icheck-clouds
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-clouds
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #ecf0f1;
}
.icheck-clouds
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-clouds > input:first-child:checked + label::before {
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}
.icheck-clouds
  > input:first-child:checked
  + input[type='hidden']
  + label::after,
.icheck-clouds > input:first-child:checked + label::after {
  border-bottom-color: #95a5a6;
  border-right-color: #95a5a6;
}
.icheck-concrete
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-concrete
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #95a5a6;
}
.icheck-concrete
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-concrete > input:first-child:checked + label::before {
  background-color: #95a5a6;
  border-color: #95a5a6;
}
.icheck-orange
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-orange
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #f39c12;
}
.icheck-orange
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-orange > input:first-child:checked + label::before {
  background-color: #f39c12;
  border-color: #f39c12;
}
.icheck-pumpkin
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-pumpkin
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #d35400;
}
.icheck-pumpkin
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-pumpkin > input:first-child:checked + label::before {
  background-color: #d35400;
  border-color: #d35400;
}
.icheck-pomegranate
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-pomegranate
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #c0392b;
}
.icheck-pomegranate
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-pomegranate > input:first-child:checked + label::before {
  background-color: #c0392b;
  border-color: #c0392b;
}
.icheck-silver
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-silver
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #bdc3c7;
}
.icheck-silver
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-silver > input:first-child:checked + label::before {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}
.icheck-asbestos
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type='hidden']
  + label::before,
.icheck-asbestos
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: #7f8c8d;
}
.icheck-asbestos
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-asbestos > input:first-child:checked + label::before {
  background-color: #7f8c8d;
  border-color: #7f8c8d;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.field {
  position: relative;
}

.field__label {
  color: #919293;
  box-sizing: border-box;
  display: block;
  font-size: 0.8125em;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: 0.5em 1em;
  pointer-events: none;
  position: absolute;
  padding-left: 1rem;
  text-overflow: ellipsis;
  top: -0.3em;
  text-align: left;
  transform: translateY(3px);
  transition: all 0.2s ease-out;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}
.field--not-empty .field__label {
  opacity: 1;
  transform: none;
}

.field__input {
  border: 2px solid #e9e9e9;
  color: #333;
  width: 100%;
  font-size: 1em;
  padding: 0.4761904762em;
  transition: all 0.2s ease-out;
}
.field__input:hover,
.field__input:focus {
  /* inset | offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: inset 0 0 10px 0 rgba(85, 85, 85, 0.1);
  outline: 0;
}
.field--not-empty .field__input {
  padding-bottom: 2px;
  padding-top: 18px;
}
.field__input {
  padding-right: 2rem !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000 !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000 !important;
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000 !important;
  opacity: 1;
}

.ui.dropdown:not(.button) > .default.text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.react-datepicker-popper {
  z-index: 2 !important;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  font-size: 14px;
  background-attachment: fixed;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #10a0e3;
}

a:hover,
a:focus {
  color: #25a9b8;
}

.rheader {
  width: 30%;
  text-align: right;
  font-weight: bold;
}

.rtext {
  text-align: center;
  word-break: break-all;
}

hr {
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.main-branding {
  text-align: center;
  min-height: 80px;
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
}

.main-branding img {
  width: 150px !important;
  margin: 45px 0 0 !important;
  transition: all 0.2s ease;
  opacity: 1 !important;
}

.boarding-container {
  margin: 100px 0 !important;
  max-width: 60em !important;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top-color: rgba(0, 0, 0, 0.125);
  border-right-color: rgba(0, 0, 0, 0.125);
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-left-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-footer {
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.btn {
  font-weight: 700 !important;
  display: inline-block !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: center !important;
  text-decoration: none !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.btn.bordered {
  color: #000 !important;
  background-color: #f9fafb !important;
  border-color: #f9fafb !important;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5) !important;
  border: none !important;
}

.btn.bordered.no-margin {
  margin: 0 !important;
}

.btn.bordered:focus,
.btn.bordered:hover {
  background-color: rgba(211, 212, 213, 0.25) !important;
}

.application-tabs {
  padding: 10px 80px;
  margin-bottom: 2rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh !important;
}

.info-icon {
  cursor: pointer;
  font-size: 20px;
  margin-right: 6px;
  margin-top: -4px;
  color: #000000;
}

.info-icon.in-input {
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 8;
}

.info-icon.masked-in-input {
  position: absolute;
  right: 0;
  top: 18px;
  z-index: 8;
}

.application-tabs .icon {
  font-size: 20px;
  margin-bottom: 5px;
}

.application-tabs .menu a {
  width: 20%;
  text-align: center;
  font-size: 11px;
  color: #6c757d;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  display: block !important;
  padding: 0.5rem 1rem !important;
  text-decoration: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
}

.application-tabs .menu a span {
  line-height: 1.5 !important;
}

.application-tabs .menu a.active {
  color: #6c757d !important;
  font-weight: 700 !important;
}

.tab-menu {
  font-family: 'Poppins', sans-serif !important;
  font-size: 11px !important;
  flex-wrap: wrap;
  list-style: none;
  display: flex;
  -webkit-flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}

.tab-pane {
  border: none !important;
  border-radius: unset !important;
  box-shadow: none !important;
}

.dark-grey {
  color: #606060;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-top: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

/* btn colors */

.btn-primary,
.btn-primary:focus,
.btn-outline-primary:focus {
  background-color: #10a0e3;
  border: solid 1px #10a0e3;
  color: #ffffff;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #10a0e3;
  border: solid 1px #10a0e3;
}

.btn-outline-light {
  border-color: rgba(0, 0, 0, 0.06);
  color: #606060;
}

.btn-outline-light:hover {
  border-color: rgba(0, 0, 0, 0.06);
  color: #606060;
}

.btn-outline-primary {
  border-color: #10a0e3;
  color: #10a0e3;
}

.btn-unborder-primary {
  border: none;
  color: #10a0e3;
  background-color: #fff;
  font-weight: 700 !important;
  cursor: pointer !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
}

.btn-unborder-primary:hover {
  color: #fff;
  background-color: #10a0e3;
  font-weight: 700 !important;
  cursor: pointer !important;
  border-radius: 3px 3px 3px 3px;
}

.btn-unborder-danger {
  border: none;
  background-color: #fff;
  color: rgb(255, 100, 82);
  font-weight: 700 !important;
  cursor: pointer !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.3rem;
  width: 100%;
  padding: 1rem;
}

.btn-unborder-danger:hover {
  border: none;
  color: #fff;
  background-color: rgb(255, 100, 82);
  font-weight: 700 !important;
  cursor: pointer !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.3rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.28571429rem;
}

.btn-success {
  background: #10a0e3;
  background: radial-gradient(to bottom right, #10a0e3, #0982c7);
  border: none;
}

.btn-success:hover {
  background-color: #0982c7;
}

.btn-success:active,
.btn-success:focus {
  box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.1) !important;
  background-color: #0982c7;
}

.btn-success.disabled,
.btn-success:disabled {
  background: #10a0e3;
  background: radial-gradient(to bottom right, #10a0e3, #0982c7);
  border: none;
}

.btn-danger {
  background-color: rgb(255, 100, 82);
  border-color: rgb(255, 100, 82);
}

/* end btn colors */

.input-error:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.1);
}

.green-text {
  color: #92d050;
}
.primary-text {
  color: #10a0e3 !important;
}

.input {
  height: 45px !important;
}

.dropdown {
  height: 45px !important;
  padding-top: 16px !important;
}

.ui.text.menu {
  margin: -2em 1.5em !important;
  margin-bottom: 0 !important;
}

/* .ui.text.menu.item {
  display: block !important;
  padding: .5rem 1rem !important;
  text-decoration: none !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out !important; 
} */
.places-autocomplete-dropdown-container {
  background-color: #ffffff;
  border: solid 1px #ced4da;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 3;
  width: 95%;
  margin-top: 0;

  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  font-size: 12px;
  color: #515151;
}

.places-autocomplete-dropdown-container .suggestion-item,
.places-autocomplete-dropdown-container .suggestion-item-active {
  cursor: pointer;
  z-index: 3;
}

.places-autocomplete-dropdown-container .suggestion-item-active {
  background-color: rgba(0, 0, 0, 1);
  z-index: 3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  letter-spacing: -0.6px !important;
  color: #333 !important;
  font-family: 'Poppins', sans-serif !important;
  margin: 0 !important;
}

.h5,
h5 {
  font-size: 1.25rem !important;
  font-family: 'Poppins', sans-serif !important;
}

.h6,
h6 {
  font-size: 1rem;
}

.owner-header-div {
  display: flex;
  margin-bottom: 1rem;
}

.owner-header-info {
  width: 100%;
}

.mb-m2 {
  margin-bottom: -2rem !important;
}

.owner-header-icon {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.progress {
  text-align: center !important;
  right: 0 !important;
  left: 0 !important;
}

.low-high-range {
  display: flex;
  justify-content: space-evenly;
  grid-gap: 2rem;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.pricing-card-low-high {
  text-align: center;
}

#main-loading-layer {
  z-index: 1060;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 1) !important;
  text-align: center;
  color: #10a0e3;
  font-size: 16px;
}

#main-loading-layer svg {
  animation: rotation 2s infinite linear;
}

.spinner-border {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-loading-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.spinner-border::after {
  content: '';
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: #10a0e3;
  border-radius: 50%;
  animation: loading 1s ease infinite;
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}

#right-panel-close {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  float: right;
  margin-top: -6px;
}

.icon-modal {
  font-size: 60px;
  margin-bottom: 15px;
  color: #ccc;
}

.modal-content {
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.ui.mini.modal {
  max-width: 300px !important;
}

.ui.modal > .close {
  top: 0 !important;
  right: 0 !important;
  color: #000 !important;
}

.copy-bank-info {
  margin-top: 0.3rem;
  font-size: 0.8rem !important;
  font-style: italic;
  cursor: pointer;
  transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.copy-bank-info:hover {
  box-shadow: 0px 0px 7px 7px rgba(217, 217, 217, 0.2);
  -webkit-box-shadow: 0px 0px 7px 7px rgba(217, 217, 217, 0.2);
  -moz-box-shadow: 0px 0px 7px 7px rgba(217, 217, 217, 0.2);
  background-color: rgba(217, 217, 217, 0.2);
}

.copy-bank-info:active {
  font-weight: 600;
}

.copy-bank-info i {
  top: 0.3rem;
  position: relative;
}

.inputcontainer {
  position: relative;
}

.icon-container {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
}

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader::after,
.loader::before {
  content: '';
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.input-calendar {
  background-image: url(/static/media/calendar.5b1359ff.svg) !important;
  background-size: 22px 22px !important;
  background-position: right 10px top 8px !important;
  background-repeat: no-repeat !important;
  font-size: 14px !important;
}

.ui.input > input {
  font-family: 'Poppins', sans-serif !important;
}

.button {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
}

.ui.black.basic.button:hover {
  background: #27292a !important;
  box-shadow: 0 0 0 1px #27292a inset !important;
  color: #eaedee !important;
}

.card-login {
  background-color: #ffffff;
  webkit-box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 27%);
  box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 27%);
  padding: 60px 80px;
  border-radius: 5px;
  /*min-width: 480px;*/
}

.mt-body3 {
  padding: 4.5rem 0;
}

.grey {
  color: #b9b7b7;
}

a.no-underline {
  text-decoration: none;
}

.primary {
  background: #10a0e3 !important;
}

.primary:hover {
  background-color: #0982c7 !important;
}

.icheck-primary label,
.popover-body label {
  font-size: 12px;
}

.icheck-primary
  > input:first-child:checked
  + input[type='hidden']
  + label::before,
.icheck-primary > input:first-child:checked + label::before {
  background-color: #10a0e3;
  border-color: #10a0e3;
}

.icheck-primary label {
  font-size: 12px;
}

.label-mini {
  /* font-size: 7px !important; */
}

.contact-desc-copy {
  display: flex;
}

.onetime {
  border-right: #212529;
}
.overflow-h {
  overflow: hidden;
}
.pricing-card-content {
  display: flex;
  justify-content: space-around;
  grid-gap: 1rem;
  gap: 1rem;
}

.header-centered {
  text-align: center;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

@media (max-width: 480px) {
  .card-login {
    width: 395px !important;
    padding: 40px 50px;
  }
}

.small-small {
  font-size: 11px !important;
}

.hide {
  display: none !important;
}

@media (max-width: 767px) {
  .application-tabs .menu a span {
    display: none;
  }

  .show-sm {
    display: flex !important;
  }

  .hide-sm {
    display: none !important;
  }

  .application-tabs {
    padding: 10px 0;
  }

  .span-sign-btn {
    display: none;
  }

  #main-loading-layer {
    font-size: 20px;
  }

  .boarding-container {
    margin: 0 0 !important;
  }
}

@media (min-width: 685px) {
  .show-sm {
    display: none;
  }
}

@media (max-width: 991px) {
  .info-icon.in-input {
    position: absolute;
    right: 0;
    top: 14px;
    z-index: 8;
  }
}

.container-sign {
  top: 10%;
  left: 10%;
  background-color: #fff;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.sigPad__penColors {
  margin-bottom: 10px;
}

.sigPad__penColors p {
  display: inline-block;
  margin-right: 5px;
}

.sigPad__penColors span {
  padding: 0px 9px;
  border-radius: 100%;
  margin-right: 5px;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}
.drag-active {
  border-color: #007acc;
  background-color: #c5e6ff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


.container-sign {
  top: 10%;
  left: 10%;
  background-color: #fff;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.sigPad__penColors {
  margin-bottom: 10px;
}

.sigPad__penColors p {
  display: inline-block;
  margin-right: 5px;
}

.sigPad__penColors span {
  padding: 0px 9px;
  border-radius: 100%;
  margin-right: 5px;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}
.drag-active {
  border-color: #007acc;
  background-color: #c5e6ff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
